export default {
  data() {
    return {
      planID: this.$route?.params?.planID,
    };
  },
  watch: {
    "$route.params.planID"(newId) {
      this.planID = newId;
    },
  },
};
